.main {
    height: 60vh;
    width: 100%;
    overflow-x: visible;
    }
.naslovi {
    /* position: absolute; */
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;

}
/* .overlay {
    position: absolute;
    width: 100%;
    height: 60vh;
    background-color: rgba(0, 0, 0, 0.111);
    z-index: 1;
} */
video {
    width: 100%;
    height: 60vh;
    object-fit: cover;
    /* z-index: 0; */
}