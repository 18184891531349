.contact {
  display: flex;
  width: auto;
  /* align-items: center; */
  flex-direction: row;
  justify-content: space-around;
  justify-content: center;
}
.company {
  text-align: left;
  margin: 20px 50px;
}
.forma {
  width: 100%;
  background: #17407a;
  text-align: center;
  color: white;
  margin: 0 auto;
  padding: 5% 10% 5% 10%;
}
.bgd {
  height: 30vh;
  width: 100%;
  display: flex;
  justify-content: center;
  /* flex-direction: row; */
  /* border: 2px solid red; */
}
.bgd img {
  /* height: 100%; */
  object-fit: contain;
  mix-blend-mode: multiply;
}


input,
textarea {
  border: 0;
  outline: 0;
  padding: 1em;
  border-radius: 8px;
  /* display: block; */
  width: 90%;
  margin-top: 1em;
  font-family: 'Merriweather', sans-serif;
  resize: none;
}

#input-submit {
  color: white;
  background: #071B3D;
  cursor: pointer;
  /* width: 70%; */
}

#input-submit:hover {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.2);
}

textarea {
  height: 126px;
}

.half {
  float: left;
  width: 100%;
  margin-bottom: 1em;
}

.right {
  width: 100%;
}

.left {
  margin-right: 2%;
}
.company h3 {
  font-size: 18px;
}


@media (max-width: 980px) {
  .half {
    width: 100%;
    float: none;
    margin-bottom: 0;
  }
  .contact {
    flex-direction: column;
  }
  .company {
    text-align: center;
  }
  .company p {
    text-align: center;
  }
  
}

.cf:before,
.cf:after {
  content: ' ';
  display: table;
}

.cf:after {
  clear: both;
}