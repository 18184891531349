.skewed {
    width: 100%;
    color: #f1f1f1;
    display: flex;
    background: #17407A;
    /* margin: 2em 0; */
    /* box-shadow: 0 0 15px rgba(0,0,0,0.7); */
}
.text {
    /* padding: 1.5em; */
    width: 50%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: column;
    margin: 20px;
}
.image {
    width: 50%;
    height: 50vh;
    position: relative;
    overflow: hidden;
    
}

.image img {
    height: 50vh;
    width: 100%;
    object-fit: cover;
}

.image::before {
    content: "";
    position: absolute;
    left: -4vw;
    top: -100px;
    transform: translate(-50%) rotate(20deg);
    background: #17407A;
    width: 40%;
    height: 140%;
    box-shadow: 0 0 20px rgba(0,0,0,0.7);
    
}
.btn {
    /* border: 1px solid white; */
    /* border-radius: 20px; */
    /* background-color: aqua; */
    /* padding: 10px; */
    /* margin: 5px; */
    text-decoration: none;
    color: white;
    font-weight: bold;
}

@media screen and (max-width: 980px) {

    .image, .text {
        width: 100%;
    }
    img {width: 160%;}
    .skewed .image::before {
        width: 120%;
        height: 40%;
        top: -5em;
        left: 50%;
        transform: translateX(-50%) rotate(10deg);
    }
  }