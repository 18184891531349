.footer {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    height: 60px;
    width: 100%;
    background-color: #3084C6;
}
.top_line {
    content: '';
    width: 100%;
    height: 10px;
    /* background-color: blueviolet; */
    display: flex;
    justify-content: flex-end;
}
.empty {
    content: '';
    height: 100%;
}
.line {

    content: '';
    width: 45%;
    height: 5px;
    background-color: #A1DBE9; 
}
 
.footer p {
    padding-top: 10px;
    color: white;
    text-align: center;
}

@media (max-width: 768px) {
.footer {
    /* padding: 10px; */
    height: auto;
 }
 .footer p {
    padding: 10px 0px;
 }
    
  }