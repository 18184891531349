.partners {
    /* height: 60vh; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #d4d4d4;
    padding: 2% 0;
}
.partners h1 {
    padding: 5% 0 5% 0;

}
.partners p {
    color: #102A42;
}
.desc {
    margin: 0% 4%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.card {
    height: auto;
    display: flex;
    flex-direction: row;
    padding: 30px;
    margin: 30px;
    border-radius: 50px;
    background: #d4d4d4;
    box-shadow:  20px 20px 60px #b4b4b4,
                 -20px -20px 60px #f4f4f4;
}
/* .card img {
    width: 20%;
    border-radius: 40px;
    object-fit: cover;
} */
.logo_comp {
    width: 20%;
    height: auto;
    }
.button {
    /* border: 2px solid red; */
    padding: 1% 2%;
    /* width: 200px; */
    text-align: center;
    color: #e0e0e0;
    border-radius: 50px;
    background: #17407A;

}
.button:hover {
    background-color: #174a7a;
}

@media screen and (max-width: 980px) {
    .card {
        flex-direction: column;
    }
    .left {
        flex-direction: column-reverse;
    }
    .card img {
        width: 100%;
        height: 200px;
    }
    .desc {
        padding-top: 2%;
        margin-left: 0;
    }
  }